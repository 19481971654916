import { Entity, Column, PrimaryGeneratedColumn } from 'typeorm';
import { IGameAction } from '../../types/game.types';
import { IGameSharePriceFactor } from '../../types/sharePrice.types';
import { IAssetTypeAdjustment } from '../../types';

@Entity({ name: 'game_actions' })
export class GameAction implements IGameAction {
  @PrimaryGeneratedColumn()
  id: number;

  @Column() triggerRoundID: number;

  @Column() actionUpdateType: 'setting' | 'sharePriceFactor' | 'scenario' | 'assetTypeAdjustment';

  @Column({ nullable: true })
  entryID: number;

  @Column({ nullable: true })
  settingValue: string;

  @Column({ type: 'json', nullable: true })
  actionParameters: IGameSharePriceFactor[] | IAssetTypeAdjustment;
}
//extend without preset
