import { Entity, Column, PrimaryGeneratedColumn, OneToMany } from 'typeorm';
import { BilateralTrade } from './BilateralTrade.entity';
import { OpenTrade } from './OpenTrade.entity';
import { OpenTradeComplete } from './OpenTradeComplete.entity';
import { TeamAssetAdjustment } from './TeamAssetAdjustment.entity';

@Entity({ name: 'teams' })
export class BaseTeam {
  @PrimaryGeneratedColumn()
  teamID: number;

  @Column()
  label: string;

  @Column({ default: null, select: false })
  passcode?: number;

  @Column()
  color: string;

  @Column()
  type: 'ADMIN' | 'PLAYER' | 'DEMO';

  @OneToMany(() => BilateralTrade, bilateralTrade => bilateralTrade.offeringTeam)
  offeringTeamID: BilateralTrade[];

  @OneToMany(() => BilateralTrade, bilateralTrade => bilateralTrade.receivingTeam)
  receivingTeamID: BilateralTrade[];

  @OneToMany(() => OpenTrade, openTrade => openTrade.offeringTeam)
  offeringTeam: OpenTrade[];

  @OneToMany(() => OpenTradeComplete, openTrade => openTrade.receivingTeam)
  receivingTeam: OpenTradeComplete[];

  @OneToMany(() => TeamAssetAdjustment, teamAssetAdjustment => teamAssetAdjustment.team)
  assetAdjustments: TeamAssetAdjustment[];

  @Column({ default: null })
  currentRound?: number;

  @Column({ default: null })
  portfolio?: number;

  @Column({ default: null })
  roundEndTime?: string;
}
