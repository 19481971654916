import { memo, useContext, useEffect, useState } from 'react';

import {
  Asset,
  HydrogenValuesProvider,
  gameProgressContext,
  setErrorContext,
  setupDataContext,
  socket,
  teamAssetsContext,
  useHover
} from '@powertrader/core';
import type { BaseTeam, IAsset, IBooleanModalProps, IGameProgress, IResultsToSave, IUpdateAssetLoadSocket } from '@powertrader/schema';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { Dashboard } from '../Dashboard/Dashboard';
import { Round2Modal } from '../Round2Modal';
import { Round3Modal } from '../Round3Modal';
import { CenterStage } from './CentreStage';
import styles from './GamePlayArea.module.css';

interface IGamePlayArea {
  setTeamAssets: React.Dispatch<React.SetStateAction<IAsset[]>>;
  setGameProgress: React.Dispatch<React.SetStateAction<IGameProgress | undefined>>;
  storedStartTime?: Date;
  gameEnded: boolean;
}
const GamePlayArea = memo(({ setTeamAssets, setGameProgress, storedStartTime, gameEnded }: IGamePlayArea) => {
  const { roundID } = useContext(gameProgressContext);
  const setError = useContext(setErrorContext);
  const teamAssets = useContext(teamAssetsContext);
  const { user } = useContext(setupDataContext);
  const [showModal, setShowModal] = useState<IBooleanModalProps['showModal']>(roundID !== 1 && !storedStartTime && !gameEnded);
  const [startTime, setStartTime] = useState<Date | undefined>(gameEnded ? undefined : storedStartTime);
  // const [endTime, setEndTime] = useState<string>();
  const [roundStatus, setRoundStatus] = useState<'started' | 'ended' | 'loading'>(gameEnded ? 'ended' : storedStartTime ? 'started' : 'loading');
  const [showResults, setShowResults] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [dontShowTip, setDontShowTip] = useState(false);

  const [genAssetRef, genAssetsIsHovered] = useHover<HTMLDivElement>();
  const [customerAssetRef, customerAssetIsHovered] = useHover<HTMLDivElement>();
  const [maximumTradesReached, setMaximumTradesReached] = useState(false);

  useEffect(() => {
    if ((genAssetsIsHovered || customerAssetIsHovered) && roundStatus === 'started' && !dontShowTip) {
      setShowTip(true);
    }
    if (roundID === 2) {
      setDontShowTip(true);
      setShowTip(false);
    }
  }, [customerAssetIsHovered, dontShowTip, genAssetsIsHovered, roundID, roundStatus]);

  // useEffect(() => {
  //   socket.emit('getTeamEndTime', user.teamID, (data: string | undefined | null) => {
  //     if (data) setEndTime(data);
  //   });
  // }, [socket, user.teamID]);

  // useEffect(() => {
  //   if (endTime)
  //     if (new Date(endTime).getTime() > new Date().getTime()) {
  //       setRoundStatus('started');
  //     } else if (new Date(endTime).getTime() < new Date().getTime()) {
  //       setRoundStatus('ended');
  //     }
  // }, [endTime]);

  const handleNextRound = (resultsToSave: IResultsToSave) => {
    socket.emit('setRoundEndTime', user.teamID, roundID, (error: Error, stopStopwatch?: boolean) => {
      if (stopStopwatch) {
        setStartTime(undefined);
      }
      if (error) {
        setError({
          error,
          message: `cannot set round end time`
        });
      }
    });

    socket.emit('proceedToNextRound', resultsToSave, (error: Error | null, team?: BaseTeam, endOfGame?: boolean) => {
      if (team) {
        const newTeamRoundID = team.currentRound;

        if (!newTeamRoundID) throw new Error('Failed Incrementing Team Round');

        if (!endOfGame) {
          setMaximumTradesReached(false);
          setRoundStatus('loading');
          setGameProgress({
            roundID: newTeamRoundID,
            scenarioID: newTeamRoundID
          });
        }
      } else if (endOfGame) {
        setShowResults(true);
        setRoundStatus('ended');
      } else {
        throw new Error('Cannot proceed to next round');
      }
    });
  };

  // const onTimeEnded = () => {
  //   setRoundStatus('ended');
  // };

  const startRound = () => {
    if (roundStatus === 'loading')
      socket.emit('setRoundStartTime', user.teamID, roundID, (startDateTime: Date) => {
        if (startDateTime) {
          setStartTime(new Date(startDateTime));
          setRoundStatus('started');
        }
      });
  };

  // const setTimeLeft = (minutesToAdd: number) => {
  //   const now = new Date().getTime();
  //   const newEndTime = new Date(now + 60000 * minutesToAdd).toString();
  //   setEndTime(newEndTime);
  //
  //   socket.emit('setTeamEndTime', user.teamID, newEndTime);
  // };

  useEffect(() => {
    if (roundStatus === 'loading' && !gameEnded && (roundID === 3 || roundID === 2)) setShowModal(true);
    if (gameEnded) {
      setRoundStatus('ended');
      setShowResults(true);
    }
  }, [gameEnded, roundID, roundStatus]);

  useEffect(() => {
    socket.on('updateAssetLoad', (data: IUpdateAssetLoadSocket) => {
      if (data !== undefined && data.teamID === user.teamID) {
        const index = teamAssets.findIndex(asset => asset.assetID === data.assetID);
        const newTeamAssets = cloneDeep(teamAssets);
        let found = false;
        newTeamAssets[index].load = teamAssets[index].load.map(load => {
          if (load.marketID === data.marketID && load.roundID === data.roundID) {
            found = true;

            return { ...load, load: data.option };
          }
          return load;
        });
        if (!found) {
          newTeamAssets[index].load.push({
            assetID: data.assetID,
            marketID: data.marketID,
            roundID: data.roundID,
            load: data.option
          });
        }

        setTeamAssets(newTeamAssets);
      } else {
        setError({
          message: 'Team sent invalid data through socket updateAssetLoad'
        });
      }
    });

    return () => {
      socket.off('updateAssetLoad');
    };
  }, [setError, socket, setTeamAssets, teamAssets, user.teamID]);

  return (
    <HydrogenValuesProvider>
      <div className={styles.gamePlayAreaContainer}>
        {roundID === 2 ? (
          <Round2Modal onOK={startRound} showRound2Modal={showModal} setShowRound2Modal={setShowModal} roundID={roundID} />
        ) : (
          <Round3Modal showModal={showModal} setShowModal={setShowModal} onOK={startRound} />
        )}

        <>
          <div className={styles.dashboard} style={{ borderColor: user.color }}>
            <Dashboard
              teamID={user.teamID}
              handleNextRound={handleNextRound}
              startTime={startTime}
              roundStatus={roundStatus}
              setMaximumTradesReached={setMaximumTradesReached}
            />
          </div>
          <div className={classNames([styles.currentTab])}>
            <div ref={genAssetRef} className={classNames([styles.generation])}>
              <h2 className={styles.sectionTitle}>Generation</h2>
              {teamAssets.filter(asset => asset.electricityLoadType === 'generator').length === 0 ? (
                <h5 className={styles.noAssets}>No Assets to Display</h5>
              ) : (
                teamAssets
                  .filter(asset => asset.electricityLoadType === 'generator')
                  .map(asset => <Asset asset={asset} key={asset.assetID} disabled={roundStatus !== 'started'} />)
              )}
            </div>

            <CenterStage
              setShowModal={setShowModal}
              startRound={startRound}
              roundStatus={roundStatus}
              showResults={showResults}
              showTip={showTip}
              setShowTip={setShowTip}
              setDontShowTip={setDontShowTip}
              maximumTradesReached={maximumTradesReached}
            />
            <div ref={customerAssetRef} className={classNames([styles.customers])}>
              <h2 className={styles.sectionTitle}>Customers</h2>
              {teamAssets.filter(asset => asset.electricityLoadType === 'customer').length === 0 ? (
                <h5 className={styles.noAssets}>No Assets to Display</h5>
              ) : (
                teamAssets
                  .filter(asset => asset.electricityLoadType === 'customer')
                  .map(asset => <Asset asset={asset} key={asset.assetID} disabled={roundStatus !== 'started'} />)
              )}
            </div>
          </div>
        </>
      </div>
    </HydrogenValuesProvider>
  );
});

export default GamePlayArea;
