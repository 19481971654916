import { IAssetTypeAdjustmentCriteria, IAssetTypeDefinition } from '../types';
import { getDataType } from './getDataType';
import { isNumericProperty } from './isNumericProperty';
import { isNumericValue } from './isNumericValue';

export const invalidCriterionProperties = [
  'typeID',
  'hydrogenInterruptible',
  'notes',
  'upgradableTypes',
  'label',
  'name',
  'icon',
  'color',
  'carbonCertificates',
  'hydrogenStorageCapacity',
  'hydrogenInterruptible',
  'hydrogenRunningTariff',
  'conversionEfficiency',
  'storageEfficiency'
];

export const isValidCriterion = (criterion: IAssetTypeAdjustmentCriteria, assetTypeDefinitions: IAssetTypeDefinition[]) => {
  // Check valid property chosen
  if (
    criterion.property === '' ||
    invalidCriterionProperties.includes(criterion.property) ||
    !assetTypeDefinitions.find(assetTypeDefinition => assetTypeDefinition.columnName === criterion.property)
  ) {
    return false;
  }
  const isNumeric = isNumericProperty(criterion.property, assetTypeDefinitions);
  // Check value is valid for property
  if (isNumeric && !isNumericValue(criterion.value)) {
    return false;
  }
  // The criterion has passed the validation
  return true;
};
