import { Brackets, EntityRepository, Repository } from 'typeorm';
import { OpenTradeComplete } from '../entities/Common/OpenTradeComplete.entity';
import { BaseTeam } from '../entities/Common/Team.entity';

@EntityRepository(OpenTradeComplete)
export class OpenTradeCompleteRepository extends Repository<OpenTradeComplete> {
  findByTeamAndRound(teamID: BaseTeam['teamID'], roundID: number) {
    return this.createQueryBuilder('otc')
      .leftJoinAndSelect('otc.receivingTeam', 'receivingTeam')
      .leftJoinAndSelect('otc.openTrade', 'ot')
      .leftJoinAndSelect('ot.offeringTeam', 'offeringTeam')
      .leftJoinAndSelect('ot.market', 'market')
      .where(
        new Brackets(qb => {
          qb.where('offeringTeam = :teamID', {
            teamID
          }).orWhere('receivingTeam = :teamID', { teamID });
        })
      )
      .andWhere('ot.startRoundID <= :roundID', { roundID })
      .andWhere('ot.endRoundID >= :roundID', { roundID })
      .getMany();
  }

  findByRound(roundID: number) {
    return this.createQueryBuilder('otc')
      .leftJoinAndSelect('otc.receivingTeam', 'receivingTeam')
      .leftJoinAndSelect('otc.openTrade', 'ot')
      .leftJoinAndSelect('ot.offeringTeam', 'offeringTeam')
      .leftJoinAndSelect('ot.market', 'market')
      .where('ot.startRoundID <= :roundID', { roundID })
      .andWhere('ot.endRoundID >= :roundID', { roundID })
      .getMany();
  }
}

/*

frontend

api

controllers layer (socket io listeners, calls service, responds)
service layer (pulls together various repo functions and returns a contracty style thing)
repository layer (custom collection of open trade - get me X from db)
entity layer (open trade entity)

*/
