import { IAssetTypeAdjustment, IAssetTypeDefinition, IAssetTypeAdjustmentCriteria, IAssetTypeAdjustmentAction } from '../types';
import { isValidAction } from './isValidAction';
import { isValidCriterion } from './isValidCriterion';

export const isValidAdjustment = (assetTypeAdjustment: IAssetTypeAdjustment, assetTypeDefinitions: IAssetTypeDefinition[]) => {
  if (assetTypeAdjustment === undefined) {
    return false;
  }
  if (
    !assetTypeAdjustment.criteria.every((crit: IAssetTypeAdjustmentCriteria) => {
      return isValidCriterion(crit, assetTypeDefinitions);
    })
  ) {
    return false;
  }
  if (
    !assetTypeAdjustment.actions.every((act: IAssetTypeAdjustmentAction) => {
      return isValidAction(act, assetTypeDefinitions);
    })
  ) {
    return false;
  }
  return true;
};
