export interface ISharePriceFactors {
  baseLimitsID: number;
  performanceType: string;
  label: string;
  absOrRel: string;
  bestExpectation: number;
  worstExpectation: number;
  growthLimit: number;
  color: string;
  displayLabel: string;
  variableName:
    | 'financialAbsoluteGrowth'
    | 'financialRelativeGrowth'
    | 'emissionsAbsoluteGrowth'
    | 'emissionsRelativeGrowth'
    | 'tradingAbsoluteGrowth'
    | 'tradingRelativeGrowth';
}

export const factorKeys = ['bestExpectation', 'worstExpectation', 'growthLimit'] as const;

export interface IUpdateSharePriceFactor {
  baseLimitsID: ISharePriceFactors['baseLimitsID'];
  keyToUpdate: typeof factorKeys[number] | null;
  value: number;
}

export interface IGameSharePriceFactor {
  baseLimitsID: ISharePriceFactors['baseLimitsID'];
  bestExpectation: ISharePriceFactors['bestExpectation'];
  worstExpectation: ISharePriceFactors['worstExpectation'];
  growthLimit: ISharePriceFactors['growthLimit'];
}

export interface ISharePriceEditingKey {
  baseLimitsID: number | null;
  key: typeof factorKeys[number] | null;
}
