import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, ManyToOne, JoinColumn } from 'typeorm';
import { BaseTeam } from './Team.entity';
import { Market } from './Market.entity';

@Entity('bilateral_trades')
export class BilateralTrade {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ default: 'BilateralTrade' })
  type: 'BilateralTrade';

  @ManyToOne(() => BaseTeam, team => team.teamID)
  @JoinColumn({ name: 'offeringTeam' })
  offeringTeam: BaseTeam;

  @ManyToOne(() => BaseTeam, team => team.teamID, { nullable: true })
  @JoinColumn({ name: 'receivingTeam' })
  receivingTeam?: BaseTeam | null;

  @Column()
  tradeType: 'powerExchange' | 'carbonMarket' | 'hydrogenMarket';

  @ManyToOne(() => Market, market => market.marketID)
  @JoinColumn({ name: 'market' })
  market: Market;

  @Column()
  dealType: 'sell' | 'buy';

  @Column()
  volume: number;

  @Column()
  price: number;

  @Column()
  status: 'offered' | 'accepted' | 'rejected' | 'deleted';

  @CreateDateColumn()
  createdAt: Date;

  @Column({ default: null, nullable: true })
  tradedAt?: Date;

  @Column()
  startRoundID: number;

  @Column()
  endRoundID: number;

  @Column({ default: null, nullable: true, type: String })
  token?: string | null;
}
