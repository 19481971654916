import { EntityRepository, Repository } from 'typeorm';
import { BaseTeam } from '../entities/Common/Team.entity';

@EntityRepository(BaseTeam)
export class TeamRepository extends Repository<BaseTeam> {
  findById(teamID: number) {
    return this.findOneOrFail({
      where: [{ teamID }]
    });
  }
}
