import { EntityRepository, LessThanOrEqual, MoreThanOrEqual, Repository } from 'typeorm';
import { BilateralTrade } from '../entities/Common';

@EntityRepository(BilateralTrade)
export class BilateralTradeRepository extends Repository<BilateralTrade> {
  findById(id: number) {
    return this.findOneOrFail({
      relations: ['receivingTeam', 'offeringTeam', 'market'],
      where: [{ id }]
    });
  }

  findByTeamAndRound(teamId: number, round: number) {
    return this.find({
      relations: ['offeringTeam', 'receivingTeam', 'market'],
      where: [
        {
          offeringTeam: teamId,
          type: 'BilateralTrade',
          startRoundID: LessThanOrEqual(round),
          endRoundID: MoreThanOrEqual(round)
        },
        {
          receivingTeam: teamId,
          type: 'BilateralTrade',
          startRoundID: LessThanOrEqual(round),
          endRoundID: MoreThanOrEqual(round)
        }
      ]
    });
  }

  findByRound(round: number) {
    return this.find({
      relations: ['offeringTeam', 'receivingTeam', 'market'],
      where: [
        {
          type: 'BilateralTrade',
          startRoundID: LessThanOrEqual(round),
          endRoundID: MoreThanOrEqual(round)
        }
      ]
    });
  }
}
