import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, ManyToOne, JoinColumn } from 'typeorm';

import { OpenTrade } from './OpenTrade.entity';
import { BaseTeam } from './Team.entity';

@Entity({ name: 'open_trade_complete' })
export class OpenTradeComplete {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ default: 'OpenTradeComplete' })
  type: 'OpenTradeComplete';

  @ManyToOne(() => OpenTrade, trade => trade.id)
  openTrade: OpenTrade;

  @ManyToOne(() => BaseTeam, team => team.teamID)
  @JoinColumn({ name: 'receivingTeam' })
  receivingTeam: BaseTeam;

  @Column()
  volume: number;

  @CreateDateColumn()
  tradedAt: Date;
}
