import { useState, useEffect, useContext, memo, useCallback, useRef, useMemo } from 'react';

import { round } from 'lodash';
import { Button, message, notification, Spin, Tooltip } from 'antd';
import { debounce } from 'underscore';
import {
  ISettings,
  ISharePriceFactors,
  IAsset,
  ITeamRoundData,
  BilateralTrade,
  OpenTradeComplete,
  IResultsToSave,
  ICalcTeamScore
} from '@powertrader/schema';

import {
  setupDataContext,
  teamAssetsContext,
  gameProgressContext,
  teamRoundDataContext,
  controlDataContext,
  setErrorContext,
  bilateralTradesContext,
  openTradesCompletedContext,
  calcTeamScores,
  Stopwatch,
  socket,
  CustomerTable
} from '@powertrader/core';

import styles from './Dashboard.module.css';
import { Targets } from './Targets';

interface IDebouncedDashResults {
  myTrades: OpenTradeComplete[];
  roundID: number;
  scenarioID: number;
  settings: ISettings;
  sharePriceFactors: ISharePriceFactors[];
  teamAssets: IAsset[];
  teamID: number;
  teamRoundData: ITeamRoundData[];
  bilateralTrades: BilateralTrade[];
  lastRoundScores: ITeamRoundData[];
}

interface IDashboard {
  teamID: number;
  handleNextRound: (resultsToSave: IResultsToSave) => void;
  startTime?: Date;
  roundStatus: 'started' | 'loading' | 'ended';
  setMaximumTradesReached: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Dashboard = memo(({ teamID, handleNextRound, startTime, roundStatus, setMaximumTradesReached }: IDashboard) => {
  const setError = useContext(setErrorContext);
  const bilateralTrades = useContext(bilateralTradesContext);
  const { user, settings, teams, scenarioDefinition, markets } = useContext(setupDataContext);
  const teamAssets = useContext(teamAssetsContext);
  const { scenarioID, roundID } = useContext(gameProgressContext);
  const { sharePriceFactors } = useContext(controlDataContext);
  const teamRoundData = useContext(teamRoundDataContext);
  const [teamScore, setTeamScore] = useState<ICalcTeamScore>();
  const myTrades = useContext(openTradesCompletedContext);
  const [isSavingResults, setIsSavingResults] = useState(false);
  const [demandHintNotificationShow, setDemandHintNotificationShow] = useState(false);
  const activeMarkets = useMemo(
    () => (settings.allowCarbonTrading ? markets : markets.filter(market => market.type === 'powerExchange')),
    [markets, settings.allowCarbonTrading]
  );
  const { currency } = settings;

  useEffect(() => {
    if (teamScore?.totals.noOfTrades === 2 && roundID === 2) {
      notification.open({
        message: 'Trading Tip',
        description: `Well done on making 2 trades, make sure each trade is profitable. Consider how much you can earn and therefore what price you should pay for it on the market. This also appies to the cost of generation vs income from selling`,
        duration: 0,
        style: { borderBottom: `10px solid green` }
      });
    }
  }, [roundID, teamScore?.totals.noOfTrades]);

  useEffect(() => {
    setIsSavingResults(false);
  }, [roundID]);

  useEffect(() => {
    if (teamScore?.totals.noOfTrades) {
      const { noOfTrades } = teamScore.totals;
      setMaximumTradesReached(noOfTrades >= 10);
      message.info(`Max 10 Trades - ${10 - noOfTrades} Trade${noOfTrades > 1 ? 's' : ''} Remaining`);
    }
  }, [setMaximumTradesReached, teamScore?.totals, teamScore?.totals.noOfTrades]);
  const lastRoundScores = teamRoundData.filter(tr => tr.roundID === roundID - 1);

  const teamType = teams.find(team => team.teamID === teamID)?.type || 'PLAYER'; // errror really
  const calculateDashboardResults = useCallback(
    async (props: IDebouncedDashResults) => {
      const { myTrades, roundID, scenarioID, settings, sharePriceFactors, teamAssets, teamID, teamRoundData, bilateralTrades, lastRoundScores } =
        props;
      const teamScoreResults: ICalcTeamScore = calcTeamScores({
        settings,
        markets,
        scenarioID,
        roundID,
        assetsToScore: [{ teamID, teamType, teamAssets }],
        cashAndStrategy: teamRoundData.filter(tr => tr.roundID === roundID),
        trades: myTrades,
        bilateralTrades,
        lastRoundScores
      })[0];

      setTeamScore(teamScoreResults);
    },
    [markets, teamType]
  );

  // useRef holds the debounce to prevent overload

  const debounceDashResults = useRef<(props: IDebouncedDashResults) => void>(
    debounce(props => {
      if (myTrades && teamRoundData) {
        calculateDashboardResults(props);
      }
    }, 250)
  );

  // use Effect Triggers calculation
  useEffect(() => {
    if (myTrades && teamRoundData && bilateralTrades) {
      debounceDashResults.current({
        myTrades,
        roundID,
        scenarioID,
        settings,
        sharePriceFactors,
        teamAssets,
        teamID,
        teamRoundData,
        bilateralTrades: bilateralTrades.filter(bT => bT.status === 'accepted'),
        lastRoundScores
      });
    }
  }, [
    calculateDashboardResults,
    myTrades,
    roundID,
    scenarioID,
    settings,
    sharePriceFactors,
    teamAssets,
    teamID,
    teamRoundData,
    bilateralTrades,
    lastRoundScores
  ]);

  useEffect(() => {
    if (teamScore) {
      const tempCustomerAccount = Math.min(teamScore.totals.supply - teamScore.totals.customerMinDemand, 0);

      if (tempCustomerAccount === 0 && roundID === 2 && !demandHintNotificationShow) {
        notification.open({
          message: 'Customer Account',
          description: `Well done on meeting your customer demand. Take a look at the flexible/interruptible assets that are available to you. These allow you to reduce or move demand.`,
          duration: 0,
          style: { borderBottom: `10px solid green` }
        });
        setDemandHintNotificationShow(true);
      }
    }
  }, [demandHintNotificationShow, roundID, teamScore]);

  if (!teamScore) {
    return (
      <div className={styles.loading}>
        <Spin size='large' />
        <h3>Loading...</h3>
      </div>
    );
  }

  const { marketTotalGen, marketTotalSupply, totals, wholesaleBalance, tradedVolumeMarket } = teamScore;

  const generationDataArray = [];
  marketTotalGen.forEach(mtg => generationDataArray.push(mtg.totalLoad));
  generationDataArray.push(-round(teamScore.carbon.generation, 1));

  const tradedDataArray = [];
  tradedVolumeMarket.forEach(tvm => tradedDataArray.push(tvm.volume));
  tradedDataArray.push(round(teamScore.carbon.trading));

  const suppliedDataArray = [];
  marketTotalSupply.forEach(mtg => suppliedDataArray.push(-mtg.totalLoad));
  suppliedDataArray.push(round(teamScore.carbon.supply));

  const balanceDataArray = [];
  wholesaleBalance.forEach(wB => balanceDataArray.push(wB.long - wB.short));
  balanceDataArray.push(round(teamScore.totals.carbonCertificates));

  const wholesaleTableData = [
    {
      key: 'generation',
      rowHeading: ' Generation',
      data: generationDataArray
    },
    {
      key: 'traded',
      rowHeading: ' Trading',
      data: tradedDataArray
    },
    {
      key: 'supplied',
      rowHeading: ' Supply',
      data: suppliedDataArray
    },
    {
      key: 'balance',
      rowHeading: 'Balance',
      data: balanceDataArray
    }
  ];

  const teamCurrentRoundData = teamRoundData.find(tr => tr.roundID === roundID);
  const customerAccount = Math.min(totals.supply - totals.customerMinDemand, 0);
  const isCustomerBalanced = customerAccount === 0;

  const isMarketsBalanced = wholesaleBalance.map(wB => ({
    marketID: wB.market.marketID,
    balanced: wB.long - wB.short >= 0
  }));

  const balanceColor = (value: number) => {
    const red = 'rgb(255, 147, 147)';
    const orange = 'rgb(245, 192, 118)';
    const green = 'rgb(139, 201, 144)';

    if (value < 0) return { background: red };
    if (value >= 1) return { background: orange };
    return { background: green };
  };

  const levelPassed = (level: number) => {
    if (level === 1) return isMarketsBalanced[0].balanced && isMarketsBalanced[1].balanced && isCustomerBalanced && totals.profit > 0;

    if (level === 2) {
      return (
        (isMarketsBalanced[0].balanced && isMarketsBalanced[1].balanced && isCustomerBalanced && teamScore?.totals.noOfTrades >= 5) ||
        teamScore?.totals.noOfTrades >= 10
      );
    }
    if (level === 3) {
      return true;
    }
  };

  const wholeImbalancePenalty = teamScore.imbalance.totalPenalty - teamScore.customerTotalShortRPenalty;
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.accountSheet}>
        <table className={styles.accountsTable}>
          <thead>
            <tr>
              <th>Account</th>
              <th style={{ borderRadius: '0px 8px 0px 0px', minWidth: '100px' }}>Totals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Opening</td>
              <td>
                {currency} {teamCurrentRoundData?.opening.toLocaleString() || 0} <span>k</span>
              </td>
            </tr>
            <tr>
              <td>Income</td>
              <td>
                {currency} {totals.income.toLocaleString()} <span>k</span>
              </td>
            </tr>
            <tr>
              <td>Expenditure</td>
              <td>
                {' '}
                {currency} {totals.expenditure.toLocaleString()} <span>k</span>
              </td>
            </tr>
            <tr>
              <td>Profit</td>
              <td style={totals.profit < 0 ? { background: 'rgb(255, 147, 147) ' } : totals.profit > 0 ? { background: 'rgb(139, 201, 144)' } : {}}>
                {currency}
                {totals.profit.toLocaleString()} <span>k</span>
              </td>
            </tr>
            <tr>
              <td>Cash</td>
              <td>
                {currency}
                {((teamCurrentRoundData?.opening || 0) + totals.profit).toLocaleString()} <span>k</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.companyInfo}>
          <h1
            style={{
              color: user.color,
              fontWeight: 'bold',
              minWidth: '250px',
              margin: '10px'
            }}>
            {teams.find(team => team.teamID === teamID)?.label}
          </h1>
          {roundID === 3 && roundStatus !== 'ended' && <Stopwatch startTime={startTime} />}
          {roundID === 3 && roundStatus === 'ended' && (
            <>
              {' '}
              <h3 style={{ color: 'green' }}>Game Completed</h3>
              <div className={styles.playAgainButtons}>
                <Button
                  shape='round'
                  type='primary'
                  style={{
                    fontSize: '16px',
                    padding: '5px 20px',
                    height: 'fit-content'
                  }}
                  onClick={() => {
                    socket.emit('logout');
                  }}>
                  Start Over
                </Button>
                <Button
                  shape='round'
                  type='primary'
                  style={{
                    fontSize: '16px',
                    padding: '5px 20px',
                    height: 'fit-content'
                  }}
                  onClick={() => {
                    sessionStorage.setItem('skip', teams.find(team => team.teamID === teamID)?.label || '');
                    sessionStorage.removeItem('user');
                    socket.emit('replayLevel3', teamID);
                  }}>
                  Replay Level 3
                </Button>
              </div>
            </>
          )}
          {roundStatus !== 'ended' && (
            <>
              {isSavingResults ? (
                <h3 style={{ color: 'green' }}>Saving Results...</h3>
              ) : (
                <Button
                  disabled={!levelPassed(roundID)}
                  shape='round'
                  type='primary'
                  style={{
                    fontSize: '16px',
                    padding: '5px 20px',
                    height: 'fit-content'
                  }}
                  onClick={() => {
                    const resultsToSave: IResultsToSave = {
                      roundID,
                      teamID,
                      opening: teamCurrentRoundData?.opening || 0,
                      profit: totals.profit,
                      emissions: totals.emissions,
                      noOfTrades: totals.noOfTrades,
                      hydrogenStored: teamScore.hydrogen.currentStored
                    };
                    handleNextRound(resultsToSave);
                    setIsSavingResults(true);
                  }}>
                  {roundID < 3 ? `Proceed To Level ${roundID + 1}` : 'Finish Level'}
                </Button>
              )}
            </>
          )}
          {roundID === 1 && (
            <Targets
              targets={[
                {
                  label: 'Balanced Wholesale Account',
                  status: isMarketsBalanced[0].balanced && isMarketsBalanced[1].balanced
                },
                {
                  label: 'Balanced Customer Account',
                  status: isCustomerBalanced
                },
                {
                  label: `Run A Profitable Company`,
                  status: totals.profit > 0
                }
              ]}
            />
          )}
          {roundID === 2 && (
            <Targets
              targets={[
                {
                  label: 'Balanced Wholesale Account',
                  status: isMarketsBalanced[0].balanced && isMarketsBalanced[1].balanced
                },
                {
                  label: 'Balanced Customer Account',
                  status: isCustomerBalanced
                },
                {
                  label: `Complete ${teamScore.totals.noOfTrades}/5 Trades`,
                  status: teamScore.totals.noOfTrades >= 5
                }
              ]}
            />
          )}
        </div>
      </div>
      <div className={styles.centerColumn}>
        <table style={{ minWidth: '275px' }} className={styles.balanceTable}>
          <thead>
            <tr>
              <th>Carbon Tax</th>
              <th>Emissons</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Tooltip
                placement='bottom'
                title={
                  <>
                    Carbon Tax: {settings.currency}
                    {settings.carbonTax} /kt.CO
                    <sub>2</sub>
                  </>
                }>
                <td
                  style={{
                    padding: '2px',
                    fontWeight: 'normal',
                    textAlign: 'center'
                  }}>
                  {settings.currency}
                  {settings.carbonTax}/t.CO<sub>2</sub>
                </td>
              </Tooltip>
              <td>
                {round(teamScore.totals.emissions, 2)} kt.CO<sub>2</sub>
              </td>
            </tr>
          </tbody>
        </table>

        <div className={styles.roundAndWeather}>
          <h3>
            <strong>Level {roundID}</strong>
          </h3>

          <h3>{scenarioDefinition.find(sd => sd.scenarioID === scenarioID)?.label}</h3>
          <p>{scenarioDefinition.find(sd => sd.scenarioID === scenarioID)?.weatherDescription}</p>
        </div>
      </div>
      <div className={styles.wholesaleColumn}>
        <table className={styles.balanceTable}>
          <thead>
            <tr>
              <th>Wholesale</th>
              {activeMarkets.map((aM, index) => (
                <th key={index}>{aM.label === 'Carbon' ? 'C.Certs' : aM.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vol. from: </td>
              {activeMarkets.map((aM, index) => (
                <td key={index} style={{ fontSize: '14px', padding: '3px 5px' }}>
                  {aM.volumeUnits}
                </td>
              ))}
            </tr>
            {wholesaleTableData.map(wtd => (
              <tr key={wtd.key}>
                <td>{wtd.rowHeading}</td>
                {activeMarkets.map((aM, index) => (
                  <td key={index} style={wtd.key === 'balance' ? balanceColor(wtd.data[index]) : {}}>
                    {wtd.data[index]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {wholeImbalancePenalty > 0 && (
          <h5 style={{ marginTop: '3px', color: 'red' }}>
            Imbalance Penalty: {settings.currency}
            {wholeImbalancePenalty}k
          </h5>
        )}
      </div>
      <div className={styles.lastColumn}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
        </div>
        <div>
          <CustomerTable
            marketData={[
              {
                label: 'Electricity',
                units: 'GWh',
                minDemand: totals.customerMinDemand,
                unfulfilled: teamScore.customerTotalShortR
              }
            ]}
          />

          {teamScore.customerTotalShortRPenalty > 0 && (
            <h5 style={{ marginTop: '3px', color: 'red' }}>
              Short Penalty: {settings.currency}
              {teamScore.customerTotalShortRPenalty}k
            </h5>
          )}
        </div>
      </div>
    </div>
  );
});
