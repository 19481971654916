import { IAssetTypeAdjustmentAction, IAssetTypeDefinition } from '../types';
import { isNumericProperty } from './isNumericProperty';
import { isNumericValue } from './isNumericValue';

export const invalidActionProperties = ['typeID', 'name', 'label', 'shortLabel', 'hydrogenInterruptible', 'notes', 'upgradableTypes'];

export const isValidAction = (action: IAssetTypeAdjustmentAction, assetTypeDefinitions: IAssetTypeDefinition[]) => {
  // Check valid property chosen

  if (
    action.property === '' ||
    invalidActionProperties.includes(action.property) ||
    !assetTypeDefinitions.find(assetTypeDefinition => assetTypeDefinition.columnName === action.property)
  ) {
    return false;
  }
  const isNumeric = isNumericProperty(action.property, assetTypeDefinitions);
  // Check adjustment type is valid for property
  if (action.adjustmentType === 'multiply' && !isNumeric) {
    return false;
  }
  // Check value is valid for property
  if (isNumeric && !isNumericValue(action.value)) {
    return false;
  }
  // The action has passed the validation
  return true;
};
