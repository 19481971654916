import { Entity, Column, PrimaryGeneratedColumn, OneToMany } from 'typeorm';
import { BilateralTrade } from './BilateralTrade.entity';
import { OpenTrade } from './OpenTrade.entity';

@Entity({ name: 'markets' })
export class Market {
  @PrimaryGeneratedColumn()
  marketID: number;

  @Column()
  name: string;

  @Column()
  label: string;

  @Column()
  volumeUnits: string;

  @Column()
  priceUnits: string;

  @Column()
  type: 'powerExchange' | 'carbonMarket' | 'hydrogenMarket';

  @Column()
  typeLabel: 'Power Exchange' | 'Carbon Market' | 'Hydrogen Market';

  @OneToMany(() => BilateralTrade, bilateralTrade => bilateralTrade.market)
  bilateralTradeMarketID: BilateralTrade[];

  @OneToMany(() => OpenTrade, openTrade => openTrade.market)
  openTradeMarketID: OpenTrade[];
}
