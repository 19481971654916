import type { Market } from '../entities/Common/Market.entity';
import { BaseTeam } from '../entities/Common/Team.entity';
import type { IGameProgress } from './contextTypes.types';

export interface IAssetType {
  color: string;
  typeID: number;
  name: string;
  label: string;
  shortLabel: string;
  electricityLoadType: 'customer' | 'generator' | null;
  hydrogenLoadType: 'producer' | 'consumer' | null;
  icon: string;
  storageEfficiency: null | number;
  conversionEfficiency: null | number;
  carbonIntensity: null | number;
  runningCost: null | number;
  electricityRunningTariff: null | number;
  hydrogenRunningTariff: null | number;
  electricityInterruptible: null | number;
  hydrogenInterruptible: null | number;
  flexible: null | number;
  subsidyIncome: null | number;
  fixedCost: null | number;
  notes: string;
  carbonCertificates: null | number;
  upgradableTypes: null | number[];
  maxScenarioMarketLoads: IMaxScenarioMarketLoads[];
  typeScenarioLimits: IScenarioTypeLimits[];
  hydrogenStorageCapacity: number | null;
  fuelType: string;
}

export interface IAssetLoad {
  assetID: IAsset['assetID'];
  marketID: Market['marketID'];
  load: number;
  roundID: IGameProgress['roundID'];
}

export interface IAsset {
  teamID: BaseTeam['teamID'];
  teamLabel: BaseTeam['label'];
  assetID: number;
  name: string;
  label: string;
  typeID: IAssetType['typeID'];
  typeName: IAssetType['name'];
  purchaseCost: null | number;
  strikePrice: null | number;
  icon: IAssetType['icon'];
  storageEfficiency: IAssetType['storageEfficiency'];
  conversionEfficiency: null | number;
  typeLabel: IAssetType['label'];
  typeShortLabel: IAssetType['shortLabel'];
  color: IAssetType['color'];
  electricityLoadType: IAssetType['electricityLoadType'];
  hydrogenLoadType: IAssetType['hydrogenLoadType'];
  carbonIntensity: IAssetType['carbonIntensity'];
  runningCost: IAssetType['runningCost'];
  electricityRunningTariff: IAssetType['electricityRunningTariff'];
  hydrogenRunningTariff: IAssetType['hydrogenRunningTariff'];
  electricityInterruptible: IAssetType['electricityInterruptible'];
  hydrogenInterruptible: IAssetType['hydrogenInterruptible'];
  hydrogenStorageCapacity: IAssetType['hydrogenStorageCapacity'];
  fixedCost: IAssetType['fixedCost'];
  carbonCertificates: IAssetType['carbonCertificates'];
  flexible: IAssetType['flexible'];
  subsidyIncome: IAssetType['subsidyIncome'];
  upgradableTypes: IAssetType['upgradableTypes'];
  notes: IAssetType['notes'];
  portfolioID: number;
  sellNext: boolean | null;
  inAuction: number;
  load: IAssetLoad[];
  maxScenarioMarketLoads: IMaxScenarioMarketLoads[];
  typeScenarioLimits: IScenarioTypeLimits[];
}

export interface IScenarioTypeLimits {
  typeID: IAsset['typeID'];
  scenarioID: IGameProgress['scenarioID'];
  energyMarketLimit: number;
  hydrogenMarketLimit: number | null;
}

export interface IMaxScenarioMarketLoads {
  typeID: IAssetType['typeID'];
  scenarioID: IGameProgress['scenarioID'];
  marketID: Market['marketID'];
  maxLoad: number;
}

export interface IAllTeamAssets {
  teamID: number;
  teamAssets: IAsset[];
  teamType: BaseTeam['type'];
}

export interface IUpdateAssetLoadSocket {
  option: number;
  assetID: number;
  teamID: number;
  marketID: number;
  roundID: number;
}

export interface IAssetsToScore {
  teamID: number;
  teamType: BaseTeam['type'];
  teamAssets: IAsset[];
}

export interface IAssetTypeAdjustment {
  criteria: IAssetTypeAdjustmentCriteria[];
  actions: IAssetTypeAdjustmentAction[];
}

export interface IAssetTypeAdjustmentCriteria {
  property: keyof IAssetType | '';
  value: string;
}

export const AssetTypeAdjustmentTypes = ['exact', 'multiply'] as const;

export interface IAssetTypeAdjustmentAction {
  property: keyof IAssetType | '';
  adjustmentType: (typeof AssetTypeAdjustmentTypes)[number];
  value: string;
}

export interface IAssetTypeDefinition {
  columnName: string;
  dataType: 'int' | 'float' | 'varchar' | 'json';
  isNullable: boolean;
}

export type BulkUpgradeAsset =
  | {
      teamID: BaseTeam['teamID'];
      assetID: IAsset['assetID'];
      typeID: IAssetType['typeID'];
      action: 'upgrade';
    }
  | { teamID: BaseTeam['teamID']; assetID: IAsset['assetID']; typeID?: IAssetType['typeID']; action: 'add' };

export type EditAssetInTeamAction = AssignAssetTeam | UnassignAssetTeam | UpgradeAssetTypeTeam | AddAssetTeam;

type BaseEditAssetInTeam = {
  selectedAsset: IAsset['assetID'];
  selectedTeam: BaseTeam['teamID'];
};
export type AssignAssetTeam = BaseEditAssetInTeam & {
  operation: 'sale';
  strikePrice: number;
  subsidyIncome?: number;
  price: number;
  newType?: IAssetType['typeID'];
  removeFromAuction: boolean;
  soldAtAuction: boolean;
  notification: string;
};

export type UnassignAssetTeam = BaseEditAssetInTeam & {
  operation: 'remove';
};

export type UpgradeAssetTypeTeam = BaseEditAssetInTeam & {
  operation: 'edit';
  newType: IAssetType['typeID'];
};

export type AddAssetTeam = BaseEditAssetInTeam & {
  operation: 'add';
  newType?: IAssetType['typeID'];
  strikePrice: number;
  subsidyIncome?: number;
  price: number;
  removeFromAuction: boolean;
};
