import { useContext, useEffect, useState } from 'react';
import { Spin, message, Switch } from 'antd';
import { IErrorProps, IUser, IUserRequest, BaseTeam, IPortfolioList } from '@powertrader/schema';
import { setErrorContext, socket } from '@powertrader/core';
import { isBrowser } from 'react-device-detect';
import styles from './LoginArea.module.css';
import { LeaderboardComponent } from '../Results/Leaderboard';
import { isNumber } from 'lodash';

interface ILoginArea {
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setControlPannel: React.Dispatch<React.SetStateAction<boolean | null>>;
  requiresLogin: boolean;
  isDemoSite: boolean;
}
export const LoginArea = ({ setUser, setIsLoggedIn, setControlPannel, requiresLogin, isDemoSite }: ILoginArea) => {
  const setError = useContext(setErrorContext);
  const [companyName, setCompanyName] = useState<string>(sessionStorage.getItem('skip') || '');
  const [userName, setUserName] = useState<string>();
  const [userAlert, setUserAlert] = useState<string>();

  const [isAttendee, setIsAttendee] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [portfolio, setPortfolio] = useState(1);
  const [portfolioList, setPortfolioList] = useState<IPortfolioList[]>();

  useEffect(() => {
    if (userName) setUserAlert(undefined);
  }, [userName]);
  useEffect(() => {
    socket.emit(
      'getPortfolios',

      (error: IErrorProps['error'] | null, data?: IPortfolioList[]) => {
        if (data) {
          setPortfolioList(data);
        } else if (error) {
          setError({
            message: `Error loading login - cannot get portfolios`,
            error
          });

          message.error('Error getting portfolios');
        }
      }
    );
  }, [setError]);

  const skip = !!sessionStorage.getItem('skip') || companyName === '235711';
  const submit = (skipTo?: boolean) => {
    try {
      if (isAttendee && !userName) {
        setUserAlert('Please provide your full name so we know you undertook this training, thank You!');
        return;
      }
      setSubmitting(true);

      socket.emit(
        'newPlayer',
        userName || companyName || 'No User Name',
        companyName || 'No Name Energy',
        portfolio,
        !!skipTo,
        (error: IErrorProps['error'], data: { userID: number; team: BaseTeam }) => {
          if (error) {
            setError({ message: 'cannot get userID', error });
          } else {
            const newUser: IUser = {
              userID: data.userID,
              userName: userName || companyName || 'No User Name',
              role: 'PLAYER',
              teamType: 'PLAYER',
              teamID: data.team.teamID,
              color: data.team.color
            };
            setUser(newUser);
            sessionStorage.setItem('user', JSON.stringify(newUser));

            socket.emit('eventLog', {
              type: 'login',
              userID: data.userID,
              log: `${data.team.label} has started playing`
            });
            if (data.team.type === 'ADMIN') {
              setControlPannel(true);
            } else {
              setControlPannel(false);
            }
            setIsLoggedIn(true);
          }
        }
      );
    } catch (error) {
      setError({
        message: `Error at Login`,
        error
      });
      setSubmitting(false);
      message.error('Error starting demo, please reload try again');
    }
  };

  const adminUserLogin = () => {
    setSubmitting(true);

    const userRequest: IUserRequest = {
      userName: 'admin',
      teamID: -1,
      role: 'ADMIN',
      teamType: 'ADMIN',
      passcode: +235711 * 3
    };
    socket.emit('adminLogin', userRequest, (error: IErrorProps['error'], insertedID: number | boolean) => {
      if (error) {
        setError({ message: 'cannot get userID', error });
      } else if (isNumber(insertedID) && insertedID > 0) {
        const newUser: IUser = {
          userID: Number(insertedID),
          userName: userRequest.userName,
          role: userRequest.role,
          teamType: userRequest.teamType,
          teamID: userRequest.teamID,
          color: 'black'
        };
        setUser(newUser);
        sessionStorage.setItem('user', JSON.stringify(newUser));

        setControlPannel(true);

        setIsLoggedIn(true);
      } else {
        setError({
          message: `Error at Login`,
          error
        });
        setSubmitting(false);
        message.error('Error starting demo, please reload try again');
      }
    });
  };

  return (
    <div className={styles.container}>
      {companyName !== 'leaderboard' && (
        <div className={styles.innerContainer}>
          <a href='https://heuristicgames.co.uk' rel='noopener noreferrer' target='_blank'>
            <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />{' '}
          </a>
          <h2>
            {!isDemoSite && 'Welcome to'} Power Trader {isDemoSite && 'Demo Site'}
          </h2>

          {!requiresLogin || submitting || !portfolioList ? (
            <div className={styles.requiresLogin}>
              <h2>Loading Game</h2>
              <Spin size='large' />
            </div>
          ) : (
            <>
              <p className={styles.welcomeMessage}>
                Power Trader will teach you the basics of running an energy company and trading on the energy market.
                <br /> <br />
                This game is based on the Power Trader workshop, to find out more about playing an advanced version of this game in a large group and
                developing a deeper understanding of the energy system, visit{' '}
                <a href='https://heuristicgames.co.uk' rel='noopener noreferrer' target='_blank'>
                  www.heuristicgames.co.uk
                </a>
                .
              </p>

              <form
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submit();
                  }
                }}
                className={styles.loginForm}>
                <div className={styles.trainingSwitch}>
                  {' '}
                  <label>Training Before Workshop </label>
                  {'   '}
                  <Switch onChange={() => setIsAttendee(prevState => !prevState)} />
                </div>
                {isAttendee && (
                  <>
                    {' '}
                    <label>Please Provide Your Full Name</label>
                    <input autoComplete='off' className={styles.inputBox} value={userName} onChange={event => setUserName(event.target.value)} />
                  </>
                )}
                <label>Create a Company Name</label>
                <input
                  autoComplete='off'
                  className={styles.inputBox}
                  value={companyName}
                  onChange={event => setCompanyName(event.target.value)}
                  maxLength={20}
                />
                <label>Select a Portfolio</label>
                <select className={styles.inputBox} name='Portfolio' value={portfolio} onChange={event => setPortfolio(+event.target.value)}>
                  <option value='' selected disabled hidden>
                    Portfolio
                  </option>
                  {portfolioList.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.id}: {p.label}
                    </option>
                  ))}
                </select>
                {skip && (
                  <button
                    className={styles.skipButton}
                    type='submit'
                    onClick={event => {
                      event.preventDefault();
                      submit(skip);
                    }}>
                    Skip To Level 3
                  </button>
                )}
                <button
                  className={styles.submitButton}
                  type={skip ? 'button' : 'submit'}
                  onClick={event => {
                    event.preventDefault();
                    submit();
                  }}>
                  {skip ? 'Start Over' : "Let's Begin!"}
                </button>

                {!isBrowser && (
                  <p style={{ color: 'rgb(207, 94, 2)', textAlign: 'center' }}>
                    <strong>Compatability Warning:</strong> Although the game will still work on mobile/tablet, the current user experience has been
                    designed for desktop only.
                  </p>
                )}
                <p className={styles.formError}>{userAlert !== '' && userAlert}</p>
              </form>
              {companyName === '235711' ? (
                <button
                  type='button'
                  onClick={event => {
                    event.preventDefault();
                    adminUserLogin();
                  }}>
                  Login as admin
                </button>
              ) : null}
            </>
          )}
        </div>
      )}
      {(companyName === '235711' || companyName === 'leaderboard') && (
        <div className={styles.leaderboardContainer}>
          <LeaderboardComponent currency='£' />
        </div>
      )}
    </div>
  );
};
