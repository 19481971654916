import { Entity, Column, PrimaryGeneratedColumn, OneToMany } from 'typeorm';
import { GamePresetAction } from './GamePresetAction.entity';

@Entity({ name: 'game_preset' })
export class GamePreset {
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @Column()
  label: string;

  @Column()
  noOfRounds: number;

  @OneToMany(() => GamePresetAction, action => action.preset)
  gamePresetActions: GamePresetAction[];
}
