import moment from 'moment-timezone';
import { EntityRepository, LessThan, LessThanOrEqual, MoreThan, MoreThanOrEqual, Repository } from 'typeorm';
import { OpenTrade } from '../entities/Common/OpenTrade.entity';
import { BaseTeam } from '../entities/Common/Team.entity';

@EntityRepository(OpenTrade)
export class OpenTradeRepository extends Repository<OpenTrade> {
  findByRound(roundID: number) {
    return this.find({
      relations: ['offeringTeam', 'market', 'completedTrades'],
      where: [
        {
          startRoundID: LessThanOrEqual(roundID),
          endRoundID: MoreThanOrEqual(roundID)
        }
      ]
    });
  }

  findByRoundAndTeam(roundID: number, team: BaseTeam) {
    return this.find({
      relations: ['offeringTeam', 'market', 'completedTrades'],
      where: [
        {
          startRoundID: LessThanOrEqual(roundID),
          endRoundID: MoreThanOrEqual(roundID),
          offeringTeam: team
        }
      ]
    });
  }

  findExpiredInRound(roundID: number) {
    const date = new Date().getTime();
    const MS_OFFSET = moment().tz('Europe/London').utcOffset() * 60 * 1000;
    const EXPIRE_TIME_IN_MS = 30 * 1000;
    const myStartDate = new Date(date - EXPIRE_TIME_IN_MS + MS_OFFSET);

    return this.find({
      where: [
        {
          offeringTeam: 1,
          createdAt: LessThan(myStartDate),
          volumeRemaining: MoreThan(0),
          startRoundID: roundID
        }
      ]
    });
  }
}
