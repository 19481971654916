import { EntityRepository, Repository } from 'typeorm';
import { GamePreset } from '../entities/Common/GamePreset.entity';

@EntityRepository(GamePreset)
export class GamePresetRepository extends Repository<GamePreset> {
  findAllWithGameActions() {
    return this.find({
      relations: ['gamePresetActions']
    });
  }

  findWithGameActions(id: number) {
    return this.findOneOrFail({
      relations: ['gamePresetActions'],
      where: {
        id
      }
    });
  }
}
