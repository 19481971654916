import { Entity, Column, PrimaryGeneratedColumn, JoinColumn, ManyToOne } from 'typeorm';
import { BaseTeam } from './Team.entity';

@Entity({ name: 'team_asset_adjustment' })
export class TeamAssetAdjustment {
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  assetID: number;

  @Column()
  typeID?: number;

  @Column()
  round?: number;

  @Column()
  action: 'upgrade' | 'add';

  @ManyToOne(() => BaseTeam, team => team.assetAdjustments)
  @JoinColumn({ name: 'teamID' })
  team: BaseTeam;
}
