import * as mysql from 'mysql2';
import { Pool } from 'mysql2/promise';
import { IConfig } from '..';

let connection: Pool;
export const poolConnect = (config?: IConfig) => {
  return new Promise(async (resolve: (R: Pool) => void, reject) => {
    try {
      if (!connection && config) {
        const newPool = mysql.createPool(config).promise();
        connection = newPool;
        resolve(connection);
      } else if (connection) {
        resolve(connection);
      } else {
        reject('No existing connections');
      }
    } catch (error) {
      reject(error);
    }
  });
};
