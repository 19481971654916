import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, ManyToOne, JoinColumn, OneToMany } from 'typeorm';
import { Market } from './Market.entity';
import { OpenTradeComplete } from './OpenTradeComplete.entity';
import { BaseTeam } from './Team.entity';

@Entity({ name: 'open_trades' })
export class OpenTrade {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ default: 'OpenTrade' })
  type: 'OpenTrade';

  @ManyToOne(() => BaseTeam, team => team.teamID)
  @JoinColumn({ name: 'offeringTeam' })
  offeringTeam: BaseTeam;

  @Column()
  tradeType: 'powerExchange' | 'carbonMarket' | 'hydrogenMarket';

  @ManyToOne(() => Market, market => market.marketID)
  @JoinColumn({ name: 'market' })
  market: Market;

  @Column()
  dealType: 'sell' | 'buy';

  @Column()
  volume: number;

  @Column()
  volumeRemaining: number;

  @Column()
  price: number;

  @OneToMany(() => OpenTradeComplete, trade => trade.openTrade)
  completedTrades: OpenTradeComplete[];

  @CreateDateColumn()
  createdAt: Date;

  @Column()
  startRoundID: number;

  @Column()
  endRoundID: number;
}
