import { IAssetTypeDefinition } from '../types';
import { getDataType } from './getDataType';

export const isNumericProperty = (property: string, assetTypeDefinitions: IAssetTypeDefinition[]) => {
  if (property === '') return false;
  const propertyDataType = getDataType(property, assetTypeDefinitions);
  if (propertyDataType === undefined) return false;
  if (propertyDataType === 'int' || propertyDataType === 'float') {
    return true;
  } else {
    return false;
  }
};
