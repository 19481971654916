import { memo, useContext, useEffect, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { IComment, IUser, IUserRequest } from '@powertrader/schema';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { socket } from '../../socket/socket';
import { CommentFeed } from './CommentFeed';
import styles from './Chat.module.css';
import { setupDataContext } from '../../context';
import { InputArea } from './InputArea';

const ReflexContainerRC = ReflexContainer as any;

const ReflexElementRC = ReflexElement as any;

const ReflexSplitterRC = ReflexSplitter as any;

interface IChat {
  user: IUser;
  allComments: IComment[];
  setAllComments: React.Dispatch<React.SetStateAction<IComment[]>>;
  logout: () => void;
  popout?: boolean;
  forceShowChat?: boolean;
}
export const Chat = memo(({ user, allComments, setAllComments, logout, popout = false, forceShowChat = false }: IChat) => {
  const { teams } = useContext(setupDataContext);

  const [showChat, setShowChat] = useState(false);
  const [forceScrollToBottom, setForceScrollToBottom] = useState<'team' | 'all' | null>();

  useEffect(() => {
    if (forceShowChat) setShowChat(true);
  }, [forceShowChat]);

  useEffect(() => {
    socket.on('newUserJoined', (data: IUserRequest) => {
      const newUserNotification = {
        comment: `${data.userName} (${teams.find(team => Number(data.teamID) === Number(team.teamID))?.label}) has joined`,
        type: 'notification'
      };
      const newAllComments = [...allComments, newUserNotification];
      // @ts-ignore - ignored as newUserNotificaiton doesn't fit the shape of IComment
      setAllComments(newAllComments);
    });
    return () => {
      socket.off('newUserJoined');
    };
  }, [allComments, setAllComments, teams]);

  const handleClearAll = () => {
    socket.emit('clearAllComments');
  };

  // const chatTypes: ('team' | 'all')[] = ['team', 'all']

  return (
    <main className={popout ? styles.popout_commentsArea : styles.commentsArea} style={showChat ? {} : { right: '-300px' }}>
      <div className={styles.buttonGuard}>
        <button onClick={() => setShowChat(prev => !prev)} className={styles.showHideChat}>
          <LeftOutlined style={showChat ? { transform: 'rotate(180deg)' } : {}} className={styles.icon} />
        </button>
      </div>
      <div className={styles.header}>
        <div className={styles.headerButtons}>
          {user.role === 'ADMIN' && (
            <Popconfirm
              title='Are you sure? This will also clear private chats'
              placement='bottomRight'
              onConfirm={() => handleClearAll()}
              okText='Yes'
              cancelText='No'>
              <Button ghost type='default' shape='round' size='small'>
                Chats Reset
              </Button>
            </Popconfirm>
          )}
          <Button ghost type='default' shape='round' size='small' onClick={() => logout()}>
            Log Out
          </Button>
          <Button
            ghost
            type='default'
            shape='round'
            size='small'
            onClick={() => {
              window.open('chat', '', 'width=350, height=700');
            }}>
            Pop Out
          </Button>
        </div>
      </div>
      <div style={{ height: 'calc(100% - 35px)' }}>
        <ReflexContainerRC orientation='horizontal'>
          <ReflexElementRC className={styles.innerChatWindow}>
            <div className={styles.commentsFeedContainer}>
              <CommentFeed
                user={user}
                allComments={allComments}
                chatType='team'
                forceScrollToBottom={forceScrollToBottom === 'team'}
                setForceScrollToBottom={setForceScrollToBottom}
              />
            </div>
            <div className={styles.commentInputArea}>
              <InputArea user={user} teams={teams} chatType='team' setForceScrollToBottom={setForceScrollToBottom} />
            </div>
          </ReflexElementRC>

          <ReflexSplitterRC className={styles.splitter} />
          <ReflexElementRC className={styles.innerChatWindow}>
            <div className={styles.commentsFeedContainer}>
              <CommentFeed
                user={user}
                allComments={allComments}
                chatType='all'
                forceScrollToBottom={forceScrollToBottom === 'all'}
                setForceScrollToBottom={setForceScrollToBottom}
              />
            </div>
            <div className={styles.commentInputArea}>
              <InputArea user={user} teams={teams} chatType='all' setForceScrollToBottom={setForceScrollToBottom} />
            </div>
          </ReflexElementRC>
        </ReflexContainerRC>
      </div>
    </main>
  );
});
