import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn } from 'typeorm';

@Entity({ name: 'leaderboard' })
export class Leaderboard {
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  userID: number;

  @Column()
  companyName: string;

  @Column()
  portfolio: number;

  @Column()
  time: number;

  @Column()
  profit: number;

  @Column({ type: 'float' })
  emissions: number;

  @CreateDateColumn()
  createdAt: Date;
}
