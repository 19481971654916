import { Entity, Column, PrimaryGeneratedColumn, ManyToOne } from 'typeorm';
import { IGameSharePriceFactor } from '../..';
import { IAssetTypeAdjustment } from '../..';

import { IGameAction } from '../../types/game.types';
import { GamePreset } from './GamePreset.entity';

@Entity({ name: 'game_preset_actions' })
export class GamePresetAction implements IGameAction {
  @PrimaryGeneratedColumn()
  id: number;

  @Column() triggerRoundID: number;

  @Column() actionUpdateType: 'setting' | 'sharePriceFactor' | 'scenario' | 'assetTypeAdjustment';

  @Column({ nullable: true })
  entryID: number;

  @Column({ nullable: true })
  settingValue: string;

  @Column({ type: 'json', nullable: true })
  actionParameters: IGameSharePriceFactor[] | IAssetTypeAdjustment;

  @ManyToOne(() => GamePreset, preset => preset.gamePresetActions)
  preset?: GamePreset; // not sure about this ?
}
