import { setErrorContext, setupDataContext, teamRoundDataContext, socket } from '@powertrader/core';
import { memo, useContext, useState } from 'react';
import { Button, message } from 'antd';
import styles from './Results.module.css';
import { calcLeaderboardScore } from '../../Util/calcLeaderboardScore';
import { LeaderboardComponent } from './Leaderboard';

export const Results = memo(() => {
  const teamRoundData = useContext(teamRoundDataContext);
  const { settings, user, teams } = useContext(setupDataContext);

  const setError = useContext(setErrorContext);
  const [addButtonIsVisible, setAddButtonIsVisible] = useState(true);

  const round3Results = teamRoundData.find(trd => trd.roundID === 3);
  if (!round3Results) {
    setError({ message: 'cannot get round 3 results for team' });
    return null;
  }

  const startTime = new Date(round3Results.startTime || 0).getTime();
  const endTime = new Date(round3Results.endTime || 0).getTime();
  const roundTime = endTime - startTime;
  const minutes = Math.floor(roundTime / 1000 / 60);
  const seconds = Math.floor((roundTime / 1000) % 60);
  const { emissions } = round3Results;
  const { profit } = round3Results;
  const hasTimeError = !(seconds >= 0 && seconds <= 60 && minutes >= 0 && minutes < 30);

  const handleAddLeadboard = () => {
    const team = teams.find(team => team.teamID === user.teamID);
    const companyName = team?.label || 'No Name Energy';
    const portflio = team?.portfolio || 0;

    socket.emit(
      'addTeamToLeaderboard',
      user.userID,
      companyName,
      portflio,
      hasTimeError ? 1000 * 60 : roundTime,
      profit,
      emissions,

      (error: Error, alreadyAdded?: boolean) => {
        if (error) {
          setError({ error, message: 'cannot get leaderboard' });
          return;
        }
        if (alreadyAdded) message.warning(`${companyName} score already added to leaderboard`);
        if (!alreadyAdded) message.success(`${companyName} added to leaderboard`);

        setAddButtonIsVisible(false);
      }
    );
  };

  return (
    <>
      <h2 style={{ marginTop: '5%' }} className={styles.sectionTitle}>
        Well Done! You've completed Power Trader Online
      </h2>
      <p className={styles.forInfo}>
        For more information about the Power Trader workshop with additional features please{' '}
        <a
          href='https://heuristicgames.co.uk/?utm_source=single_player&utm_medium=single_player&utm_campaign=single_player'
          rel='noopener noreferrer'
          target='_blank'>
          click here
        </a>
      </p>
      <div className={styles.scoreBoard}>
        <div className={styles.userTimes}>
          <table>
            <thead>
              <tr>
                <th>Score</th>
                {!hasTimeError && <th>Time</th>}
                <th>Profit</th>
                <th>Emissions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {Math.max(0, calcLeaderboardScore(hasTimeError ? 1000 * 60 : roundTime, profit, emissions))}</td>
                {!hasTimeError && (
                  <td>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </td>
                )}
                <td>
                  {settings.currency} {profit} k
                </td>
                <td>
                  {emissions} kt.CO<sub>2</sub>
                </td>
              </tr>
            </tbody>
          </table>
          {addButtonIsVisible ? (
            <Button shape='round' style={{ margin: '10px' }} className={styles.button} onClick={() => handleAddLeadboard()}>
              Add to public leaderboard
            </Button>
          ) : (
            <h4 style={{ color: '#49DE48' }}>Submitted to leaderboard below</h4>
          )}
          <div className={styles.logoContainer}>
            <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
          </div>
        </div>
        <div className={styles.leaderboardContainer}>
          <LeaderboardComponent currency={settings.currency} />
        </div>
      </div>
    </>
  );
});
